import { isOnServer } from '@whitelabel/helpers/utils';
import { getLocalStorage } from '@whitelabel/helpers/storageUtils';

type FeatureFlagCondition =
  // either percentage or languages must be defined
  | {
      percentage?: number;
      languages: string[];
    }
  | {
      percentage: number;
      languages?: string[];
    };

/**
 * The value of the feature flag must be a string or a FeatureFlagCondition object
 * string
 *  - will be used as a pass to enable the feature
 *  - If this value is set to 'ON' then the feature will be default enabled
 * FeatureFlagCondition - will be used to enable the feature based on the conditions:
 * 1. percentage - the percentage of users that will see the feature, the number value has to be out of a 100
 * 2. languages - the languages that will be enabled for the feature
 */
type FeatureFlag = Record<'claimAssessmentDelayBanner' | 'bwChatFlag', FeatureFlagCondition | string>;

const FEATURE_FLAGS: FeatureFlag = {
  claimAssessmentDelayBanner: 'OFF',
  bwChatFlag: 'ube12DB3fsd0s',
};

function probability(n: number) {
  if (n > 100) {
    throw new Error('Probability has to be less than 100%');
  }
  return !!n && Math.random() <= n / 100;
}

function getFeatureKey(key: string, customerID: string | number | undefined, locale?: string) {
  return ['FEAT', key, customerID, locale].filter(Boolean).join('-');
}

/**
 * Usage:
 *
 * ```
 * const isEnabled = checkFeatureFlag('CLAIMS_DETAIL_PAGE', customer?.id);
 *
 * if (isEnabled) {} else {}
 * ```
 *
 * @param key: the key of the feature flags
 * @param key: customer id from the store
 */
export function checkFeatureFlag(
  key: keyof typeof FEATURE_FLAGS,
  customerID: string | number | undefined,
  locale?: string,
) {
  if (isOnServer || !customerID) return false;

  const isFeatureFlagHit =
    typeof FEATURE_FLAGS[key] !== 'string' && getLocalStorage(getFeatureKey(key, customerID, locale)) === 'ON';
  const hasPass = FEATURE_FLAGS[key] === getLocalStorage(key);
  const isFeatureTurnedOn = FEATURE_FLAGS[key] === 'ON';

  return isFeatureFlagHit || hasPass || isFeatureTurnedOn;
}

/**
 * If feature flag hits, it remains on for the user unless the browser cache is cleared
 */
export function initFeatureFlags(customerID: string | number | undefined, locale?: string) {
  if (!customerID) return;

  Object.entries(FEATURE_FLAGS).forEach(([key, value]) => {
    const featureKey = getFeatureKey(key, customerID, locale);
    if (typeof value === 'string' || getLocalStorage(featureKey)) return;

    let isEnabled = false;
    const { percentage, languages } = value;

    if (percentage && !languages) {
      isEnabled = probability(percentage);
    } else if (!percentage && languages) {
      isEnabled = languages.includes(locale || '');
    } else if (percentage && languages) {
      isEnabled = probability(percentage) && languages.includes(locale || '');
    }

    if (isEnabled) {
      localStorage.setItem(featureKey, 'ON');
    }
  });
}
