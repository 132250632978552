import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as FullStory from '@fullstory/browser';
import { useIntl } from 'react-intl';
import { IBooking } from '@whitelabel/helpers/types';
import { checkFeatureFlag, initFeatureFlags } from '../../helpers/featureFlag';
import api from '../../helpers/api';
import { getAPIHost } from '../../helpers/multiRegion';
import { isAmazonGroup } from '../../helpers/chatWidget';
import { BW_CHAT_BOT_ID, BW_CHAT_BUNDLE_URL, BW_CHAT_SCRIPT_ID } from './helpers';
import { BWChatContext } from './context';

interface IBWChatProviderProps {
  userId?: string;
}

const BWChatProvider = ({ userId, children }: PropsWithChildren<IBWChatProviderProps>) => {
  const isLoading = useRef(false);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const scriptRef = useRef<HTMLScriptElement>();
  const botRef = useRef<HTMLDivElement>();
  const { locale } = useIntl();

  const clearChat = useCallback(() => {
    setIsChatVisible(false);
    if (!window.bwChatWidget) return;
    window.bwChatWidget.clearInstance();
    scriptRef.current?.remove();
  }, []);

  const setupChat = useCallback(async () => {
    if (
      !userId ||
      !BW_CHAT_BUNDLE_URL ||
      isLoading.current ||
      !!window.bwChatWidget ||
      !checkFeatureFlag('bwChatFlag', userId, locale)
    )
      return;
    const response = await api.get(
      `${getAPIHost()}/customers/${userId}/bookings/?language=en&status=CONFIRMED%7CCANCELLED&limit=50`,
      true,
    );
    const bookings = response.results as IBooking[];
    const hasAmazonBooking = bookings.some((booking) => isAmazonGroup(booking.partner?.slug));
    if (hasAmazonBooking) return;
    isLoading.current = true;
    scriptRef.current = document.createElement('script');
    botRef.current = document.createElement('div');
    botRef.current.id = BW_CHAT_BOT_ID;
    scriptRef.current.src = BW_CHAT_BUNDLE_URL;
    scriptRef.current.async = true;
    scriptRef.current.id = BW_CHAT_SCRIPT_ID;
    scriptRef.current.onload = () => {
      window.bwChatWidget?.setup(BW_CHAT_BOT_ID, {
        user_id: userId,
      });
      FullStory.event('BW Chat', { userId });
      window.bwtag('record', 'bw_chat', { userId });
      setIsChatVisible(true);
      isLoading.current = false;
    };
    document.body.appendChild(botRef.current);
    document.head.appendChild(scriptRef.current);
  }, [userId]);

  useEffect(() => {
    if (userId) {
      initFeatureFlags(userId, locale);
      setupChat();
    } else {
      clearChat();
    }
  }, [userId, setupChat, clearChat, locale]);

  const contextValue = useMemo(
    () => ({
      isChatVisible,
    }),
    [isChatVisible],
  );

  return <BWChatContext.Provider value={contextValue}>{children}</BWChatContext.Provider>;
};

export default BWChatProvider;
