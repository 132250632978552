import get from 'lodash/fp/get';
import {
  POLICY_TYPE,
  TRAVEL_POLICY_TYPES,
  PRODUCT_POLICY_TYPES,
  EVENT_POLICY_TYPES,
  PARCEL_POLICY_TYPES,
  WARRANTY_POLICY_TYPES,
  PARAMETRIC_POLICY_TYPES,
  CYBER_POLICY_TYPES,
  PET_POLICY_TYPES,
} from '@whitelabel/helpers/constants';
import { IBookingCardAttributes, IBookingCardAttributesDetails, IBooking, IQuote } from '@whitelabel/helpers/types';
import { dateISOtoAge } from '@whitelabel/helpers/date';
import {
  BOOKING_ID,
  REGEX_INS_ALL_CASES,
  SHOPEE_PARTNER_ID,
  ETRAVELI_PARTNER_ID,
  AMAZON_PARTNER_ID,
} from '@whitelabel/xcover-shared/helpers/constants';
import { ICOIResponse } from '@whitelabel/xcover-shared/helpers/types';
import xcoverCommonMessages from './messages/commonMsg';
import { PolicyTypes, IAttributes } from './types';

export const getPolicyTitle = (policy: any, intl?: any, isCyberInsurance = false) => {
  if (!isCyberInsurance) {
    return get('content.title', policy) || policy.policyName;
  }

  return intl.formatMessage(xcoverCommonMessages.insuredLabelCyberProtection);
};

export const getAllQuotesFromBooking = (booking: any) => {
  const policyTypes = booking && Object.values(booking.policyTypes);
  return policyTypes && policyTypes?.flatMap(({ quotes }: { quotes: any }) => quotes);
};

export const getInsuredLabel = (policyType: any, insured: any, intl: any, partnerId: any, itemCount: number) => {
  if (SHOPEE_PARTNER_ID === partnerId) {
    return intl.formatMessage(xcoverCommonMessages.insuredLabelShopee, { itemCount });
  }

  if (TRAVEL_POLICY_TYPES.includes(policyType)) {
    return intl.formatMessage(xcoverCommonMessages.insuredLabelTravel, {
      itemCount,
    });
  }

  if (EVENT_POLICY_TYPES.includes(policyType)) {
    return intl.formatMessage(xcoverCommonMessages.insuredLabelEvent, { itemCount });
  }

  if (PRODUCT_POLICY_TYPES.includes(policyType)) {
    return intl.formatMessage(xcoverCommonMessages.insuredLabelProduct, { itemCount });
  }

  if (PARCEL_POLICY_TYPES.includes(policyType)) {
    return intl.formatMessage(xcoverCommonMessages.insuredLabelParcel, { itemCount });
  }

  if (PARAMETRIC_POLICY_TYPES.includes(policyType)) {
    return intl.formatMessage(xcoverCommonMessages.insuredLabelLocation, { itemCount });
  }

  if (PET_POLICY_TYPES.includes(policyType)) {
    return intl.formatMessage(xcoverCommonMessages.insuredLabelPet);
  }

  if (CYBER_POLICY_TYPES.includes(policyType)) {
    // Quote comes back with 0 items in `insured` array, fix for message
    const updatedItemCount = itemCount === 0 ? 1 : itemCount;
    return intl.formatMessage(xcoverCommonMessages.insuredLabelCompany, { itemCount: updatedItemCount });
  }

  return intl.formatMessage(xcoverCommonMessages.insuredLabelDefault, {
    itemCount,
  });
};

export const getInsuredItems = (
  policyType: any,
  insured: any,
  attributesArray: IAttributes[],
  confirmedAt: any,
  intl: any,
  partnerTransactionId: any,
) => {
  const insuredItems: { name: string; attributes: (string | number)[] }[] = [];

  if (TRAVEL_POLICY_TYPES.includes(policyType) && insured) {
    return insured.map(({ firstName, lastName, birthDate }: any) => ({
      name: `${firstName} ${lastName}`,
      attributes: birthDate ? [dateISOtoAge(birthDate, confirmedAt)] : [],
    }));
  }

  if (EVENT_POLICY_TYPES.includes(policyType)) {
    attributesArray.forEach(({ eventName }) => {
      if (eventName) insuredItems.push({ name: eventName, attributes: [] });
    });
    return insuredItems;
  }

  if (PRODUCT_POLICY_TYPES.includes(policyType)) {
    attributesArray.forEach(({ brand, model, description }) => {
      if (brand || model) {
        insuredItems.push({ name: [brand, model].join(' ').trim(), attributes: [] });
      } else if (description) {
        insuredItems.push({ name: description, attributes: [] });
      }
    });
    return insuredItems;
  }

  if (PARCEL_POLICY_TYPES.includes(policyType) && partnerTransactionId) {
    return [
      { name: `${intl.formatMessage(xcoverCommonMessages.insuredItemParcel)}${partnerTransactionId}`, attributes: [] },
    ];
  }

  if (WARRANTY_POLICY_TYPES.includes(policyType)) {
    attributesArray.forEach(({ serialNumber }) => {
      if (serialNumber)
        insuredItems.push({
          name: `${intl.formatMessage(xcoverCommonMessages.insuredItemTracker)} ${serialNumber}`,
          attributes: [],
        });
    });
    return insuredItems;
  }

  if (PARAMETRIC_POLICY_TYPES.includes(policyType)) {
    attributesArray.forEach(({ policyholder: { address1, city, country, postcode } = {} }) => {
      if (address1) {
        insuredItems.push({ name: `${address1}, ${city}, ${country}, ${postcode}`, attributes: [] });
      } else if (country) {
        insuredItems.push({ name: `${country}, ${postcode}`, attributes: [] });
      }
    });
    return insuredItems;
  }

  if (PET_POLICY_TYPES.includes(policyType)) {
    attributesArray.forEach(({ petName }) => {
      if (petName) insuredItems.push({ name: petName, attributes: [] });
    });
    return insuredItems;
  }

  if (CYBER_POLICY_TYPES.includes(policyType)) {
    if (insured && insured[0]) {
      insuredItems.push({ name: insured[0].firstName, attributes: [] });
    }
  }

  return insuredItems;
};

export const getQuote = (quoteID: string, booking: IBooking) =>
  Object.values(booking.policyTypes)
    .reduce((quotes, policyType) => quotes.concat(policyType.quotes), [] as any[])
    .find((quote) => quoteID === quote.id);

export const shapeBookingAttributes = (attributes: IBookingCardAttributes[]): IBookingCardAttributesDetails[] =>
  attributes.map(({ value }) => ({ ...value }));

export const getBookingPremiumsProps = (coiResponse: ICOIResponse, isCyberInsurancePolicy = false): any => {
  const {
    surcharges,
    currency,
    totalPremiumFormatted,
    totalPriceFormatted,
    taxes,
    coiPage,
    partner,
    totalSurchargeFormatted,
  } = coiResponse;

  return {
    surcharges,
    currency,
    totalPremiumFormatted,
    totalPriceFormatted,
    totalSurchargeFormatted,
    taxes,
    premiumLabel: isCyberInsurancePolicy ? 'Cyber Protection' : coiPage?.priceLabel,
    totalLabel: coiPage?.totalLabel,
    partnerId: partner.id,
    hideTaxWhenZero: coiPage?.hideTaxWhenZero,
    isCyberInsurancePolicy,
  };
};

export const hasCOVIDPolicy = (policyTypes: PolicyTypes | any): boolean =>
  Object.keys(policyTypes).includes(POLICY_TYPE.TRAVEL_COVID);

/* 
  - if one or more quote is cancellable then return true.
  - if all quotes are not cancellable then return false.
  */
export const isBookingCancellable = (booking: IBooking) => {
  const policyTypes = booking && Object.values(booking.policyTypes);
  return policyTypes?.some(({ quotes }: { quotes: IQuote[] }) =>
    Object.values(quotes).some(({ canBeCancelled }) => canBeCancelled),
  );
};

export const isBookingModifiable = (booking: IBooking) => {
  const policyTypes = booking && Object.values(booking.policyTypes);
  return policyTypes?.some(({ quotes }: { quotes: IQuote[] }) =>
    quotes.some((quote: IQuote) => quote.bookingModificationEnabled),
  );
};

export const isPolicyHolderModifiable = (booking: IBooking) => {
  const { allowUpdates } = booking?.policyholder || {};
  return allowUpdates;
};

export const checkIfClaimExists = (booking: IBooking) =>
  booking &&
  !!Object.values<any>(booking.policyTypes).find(({ quotes }) =>
    quotes.find(({ claimExists }: { claimExists: boolean }) => claimExists),
  );

export const checkNoPermissionForModification = (booking: IBooking) => {
  const allowedMainPartnerIds = [ETRAVELI_PARTNER_ID, AMAZON_PARTNER_ID];
  const {
    policyTypes,
    partner: { id: mainPartnerId },
  } = booking;
  return allowedMainPartnerIds.includes(mainPartnerId) && hasCOVIDPolicy(policyTypes);
};

export const storeINSInLocalStorage = () => {
  const url = window.location.href;
  const INS = url.match(REGEX_INS_ALL_CASES)?.[0].toUpperCase();

  if (INS) {
    localStorage.setItem(BOOKING_ID, INS);
  }
};
